import React, { useEffect, useCallback } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { navigate } from "gatsby"

import QuoteFormContainer from "./containers/QuoteFormContainer"

import {
  MainWrapper,
  Center,
  PageTitle,
  MainContent,
  Wrapper,
} from "../components"

import { getQuote, upsertQuote, finaliseQuote } from "../services/quote"
import { getMatchingQuotingToolVersion } from "../services/utils"

import PhoneMockup from "../../../assets/Illustrations/Bunch_InstaStory_1.jpg"
import Benefits from "./benefits.png"

const useStyles = makeStyles(theme => ({
  benefitsIndicators: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  benefitsIndicator: {
    width: 25,
    height: 9,
    backgroundColor: "#CFE2EF",
    margin: "0 3px",
    borderRadius: 10,
  },
  benefitsIndicatorActive: {
    backgroundColor: "#DC5F5C",
  },
  studentButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    padding: "0px 10px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
  studentYesButton: {
    marginLeft: "20px",
    width: "180px !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      marginLeft: "0px",
    },
  },
  studentNoButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      marginBottom: "20px",
    },
  },
  customerType: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "100px 20px",
    flexDirection: "column",
    maxWidth: "1000px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  customerTypeButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    gap: "20px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      display: "block",
      width: "100%",
    },
  },
  customerTypeButton: {
    height: "300px",
    width: "400px",
    padding: "30px",
    border: "1px solid #FAFAFA",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      width: "100%",
      height: "130px",
      padding: "10px",
      justifyContent: "flex-end",
      marginBottom: "20px",
    },
  },
  customerTypeButtonText: {
    color: "#82D0FF",
    fontWeight: "normal",
    fontSize: 25,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      fontSize: 20,
    },
  },
  customerTypeButtonImage: {
    height: "calc(100% - 24px)",
  },
  quoteSummaryTagline: {
    fontSize: "2rem",
    lineHeight: "1.3125",
    fontWeight: "700",
    textRendering: "optimizelegibility",
    padding: "0px",
    margin: "0px 0px 0.5rem",
    marginBottom: 20,
    textAlign: "center",
    fontFamily: "WorkSansExtraBold",
    color: "#FAFAFA",
  },
  quoteSummaryDescription: {
    color: '#FAFAFA',
    fontSize: "23px"
  },
  phoneMockup: {
    maxWidth: "100%",
    height: 500,
    transform: "rotate(352deg)"
  }
}))

export default function QuotePage({
  currentQueryStrings,
  toolId,
  quoteId,
  location,
  siteData,
  restrictionMode
}) {
  const classes = useStyles()

  const [progress, setProgress] = React.useState(0)
  const [errors, setErrors] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [gettingQuote, setGettingQuote] = React.useState(quoteId)

  const sessionStorageTenancyDetailsStr =  sessionStorage.getItem(
    "theBunch-tenancyDetails"
  )
  const sessionStorageTenancyDetails =
    sessionStorageTenancyDetailsStr && JSON.parse(sessionStorageTenancyDetailsStr)

  const [tenancyDetails, setTenancyDetails] = React.useState(
    sessionStorageTenancyDetails || {}
  )
  const [packageDetails, setPackageDetails] = React.useState([])
  const [services, storeServices] = React.useState([])
  const [quoteExpiry, storeQuoteExpiry] = React.useState({})
  const [showBenefits, setShowBenefits] = React.useState(false)
  const [benefitsIndex, setBenefitsIndex] = React.useState(0)
  const [
    availableBroadbandProducts,
    storeAvailableBroadbandProducts,
  ] = React.useState([])
  const [showHoldingPage, setShowHoldingPage] = React.useState(false)
  const [isScottishPostcode, setIsScottishPostcode] = React.useState(false)

  let matchingQuotingToolVersion = getMatchingQuotingToolVersion(
    siteData,
    toolId
  )
  let matchingQuotingToolPages =
    matchingQuotingToolVersion &&
    matchingQuotingToolVersion.quotingToolPages.sort((a, b) =>
      a.pageNumber > b.pageNumber ? 1 : -1
    )

  const isHomeownerOrRenter =
    matchingQuotingToolVersion.type.includes("Homeowner") ||
    matchingQuotingToolVersion.type.includes("Renter") ||
    matchingQuotingToolVersion.type.includes("Residential")

  useEffect(() => {
    try {
      if (tenancyDetails && window.intercomSettings && tenancyDetails.Email) {
        window.intercomSettings = {
          app_id: 'ttny5ygb',
          name: `${tenancyDetails.FirstName} ${tenancyDetails.LastName}`, // Full name
          email: tenancyDetails.Email,
        };
      }
    } catch (e) {
    }
  }, [tenancyDetails])

  useEffect(() => {
    if (matchingQuotingToolVersion && !quoteId) {
      let initialPayload = {
        IsStudent: matchingQuotingToolVersion.type === "Students",
      }

      const pages = matchingQuotingToolVersion.quotingToolPages

      if (pages.length > 0) {
        pages.forEach(page => {
          if (page.quotingToolPageFields.length > 0) {
            page.quotingToolPageFields.forEach(pageField => {
              if (pageField.defaultValue) {
                initialPayload[pageField.name] = pageField.defaultValue
              }
            })
          }
        })

        let queryStringPayload = {
          FirstName: currentQueryStrings.firstName || "",
          LastName: currentQueryStrings.lastName || "",
          Phone: currentQueryStrings.phone || "",
          Email: currentQueryStrings.email ? decodeURIComponent(currentQueryStrings.email) : "",
        }

        if (currentQueryStrings.tenants) {
          queryStringPayload.NumberOfTenants = currentQueryStrings.tenants
        }

        if (currentQueryStrings.length) {
          queryStringPayload.TenancyLength = parseInt(
            currentQueryStrings.length
              .replace(" months", "")
              .replace("%20months", "")
          )
        }

        if (
          currentQueryStrings.addressLine1 &&
          currentQueryStrings.addressLine2 &&
          currentQueryStrings.city &&
          currentQueryStrings.postCode
        ) {
          queryStringPayload.Address = {
            addressLine1: currentQueryStrings.addressLine1,
            addressLine2: currentQueryStrings.addressLine2,
            city: currentQueryStrings.city,
            postCode: currentQueryStrings.postCode,
            country: currentQueryStrings.country,
          }
        }

        initialPayload = {
          ...initialPayload,
          ...queryStringPayload,
        }

        setTenancyDetails(initialPayload)
      }
    }
  }, [matchingQuotingToolVersion])

  // Take the response from getting a quote, and format it so that quoting tool can display it
  const storeQuote = useCallback(response => {
    if (response && response.isSuccess && response.value) {
      setTenancyDetails(JSON.parse(response.value.tenancyDetails))
      setIsScottishPostcode(response.value.isScottishPostcode)

      if (response.value.packageDetails) {
        setPackageDetails(JSON.parse(response.value.packageDetails))
      }
      storeServices(response.value.prices.services)
      storeAvailableBroadbandProducts(response.value.availableBroadbandProducts)
      storeQuoteExpiry({
        quotePricesExpired: response.value.quotePricesExpired,
        quotePricesExpireAt: response.value.quotePricesExpireAt,
      })
    }
  }, [])

  const getExistingQuote = async () => {
    if (quoteId) {
      setGettingQuote(true)
      try {
        const getQuoteResponse = await getQuote(quoteId)

        if (!getQuoteResponse.isSuccess && getQuoteResponse.redirectUrl) {
          return window.location.replace(getQuoteResponse.redirectUrl)
        }

        storeQuote(getQuoteResponse)

        // We check whether the url specified a quote step that the customer is on, if so, set the progress
        if (window.location.hash.indexOf("#Quote-Step") !== -1) {
          let stepProgress =
            parseInt(window.location.hash.replace("#Quote-Step-0", "")) - 1

          setProgress(
            Math.min(stepProgress, matchingQuotingToolPages.length - 1)
          )
        }
      } catch (e) {
        console.log(e)
      }
      setGettingQuote(false)
    }
  }

  React.useEffect(() => {
    if (matchingQuotingToolPages) {
      document.title = `Bunch - ${matchingQuotingToolPages[progress].stepTitle}`
    }
  }, [progress, matchingQuotingToolPages])

  React.useEffect(() => {
    // When the quote first loads, if there is an existing quote id, then we need to go and fetch the quote
    getExistingQuote()
  }, [quoteId, currentQueryStrings.quoteId, storeQuote])

  React.useEffect(() => {
    if (matchingQuotingToolVersion.benefitImages && showBenefits) {
      setTimeout(() => {
        if (
          !matchingQuotingToolVersion.benefitImages ||
          benefitsIndex ===
          matchingQuotingToolVersion.benefitImages.split("\n").length - 1
        ) {
          setShowBenefits(false)
        } else {
          setBenefitsIndex(benefitsIndex + 1)
        }
      }, 3000)
    } else if (matchingQuotingToolVersion.benefits && showBenefits) {
      setTimeout(() => {
        if (
          !matchingQuotingToolVersion.benefits ||
          benefitsIndex ===
          matchingQuotingToolVersion.benefits.split("\n").length - 1
        ) {
          setShowBenefits(false)
        } else {
          setBenefitsIndex(benefitsIndex + 1)
        }
      }, 3000)
    }
  }, [showBenefits, benefitsIndex])

  const renderBenefits = () => {
    if (matchingQuotingToolVersion.benefitImages) {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={
              matchingQuotingToolVersion.benefitImages.split("\n")[
              benefitsIndex
              ]
            }
            style={{
              maxWidth: "100%",
              maxHeight: "calc(100vh - 200px)",
            }}
          />
        </div>
      )
    }

    return (
      <div style={{ backgroundColor: "#AFCFE3", marginTop: 20 }}>
        <div
          style={{
            margin: "30px auto",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Sit back and relax
        </div>
        <div
          style={{
            color: "#DC5F5C",
            fontSize: 30,
            margin: "0 auto",
            marginBottom: 40,
            maxWidth: 400,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {matchingQuotingToolVersion.benefits &&
            matchingQuotingToolVersion.benefits.split("\n")[benefitsIndex]}
        </div>
        <div className={classes.benefitsIndicators}>
          {matchingQuotingToolVersion.benefits &&
            matchingQuotingToolVersion.benefits
              .split("\n")
              .map((benefit, index) => (
                <div
                  className={clsx(classes.benefitsIndicator, {
                    [classes.benefitsIndicatorActive]: benefitsIndex === index,
                  })}
                />
              ))}
        </div>
        <img src={Benefits} style={{ maxWidth: "100%" }} />
      </div>
    )
  }

  const renderContent = () => {
    if (
      (matchingQuotingToolVersion.benefits ||
        matchingQuotingToolVersion.benefitImages) &&
      showBenefits
    ) {
      return (
        <>
          <Wrapper
            location={location}
            matchingQuotingToolVersion={matchingQuotingToolVersion}
            group={{ quote: {} }}
            toolId={toolId}
            quoteId={quoteId}
            isHomeownerOrRenter={isHomeownerOrRenter}
          >
            <MainWrapper>
              <MainContent>
                <PageTitle
                  // goBack={goBack}
                  style={{ textAlign: "left" }}
                >
                  <div className="quotingTool-pageTitle">
                    Fantastic, just getting the available services
                  </div>
                </PageTitle>
                {renderBenefits()}
              </MainContent>
            </MainWrapper>
          </Wrapper>
        </>
      )
    }

    if (gettingQuote) {
      return (
        <Wrapper
          location={location}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
          group={{ quote: {} }}
          toolId={toolId}
          quoteId={quoteId}
        >
          <Center>
            <CircularProgress />
          </Center>
        </Wrapper>
      )
    }

    // If the url that the user has visited doesn't have a tool id, then we need to pick the default tool layout, which is set in Zoho
    if (
      !toolId &&
      siteData.allSiteSettings &&
      siteData.allSiteSettings.edges[0].node
    ) {
      toolId = siteData.allSiteSettings.edges[0].node.defaultQuoteToolLayout
        .toLowerCase()
        .replace(/_/, "-")
    }

    if (!matchingQuotingToolVersion) {
      return <div />
    }

    const handleContinueFromTenancyDetailsPage = async () => {
      if (progress + 1 < matchingQuotingToolVersion.quotingToolPages.length) {
        setLoading(true);
        setShowHoldingPage(true);
        sessionStorage.setItem(
          "theBunch-tenancyDetails",
          JSON.stringify(tenancyDetails)
        )

        // if (
        //   matchingQuotingToolPages &&
        //   progress + 1 === matchingQuotingToolPages.length - 1
        // ) {
        //   setShowBenefits(true)
        // }

        const result = await upsertQuote({
          quoteId,
          tenancyDetails: JSON.stringify(tenancyDetails),
          packageDetails: JSON.stringify(packageDetails),
          utm_source: currentQueryStrings.utm_source,
          utm_campaign: currentQueryStrings.utm_campaign,
          utm_medium: currentQueryStrings.utm_medium,
          utm_term: currentQueryStrings.utm_term,
          utm_content: currentQueryStrings.utm_content,
          gclid: currentQueryStrings.gclid,
          fbclid: currentQueryStrings.fbclid,
          referral_code:
            currentQueryStrings.fpr || currentQueryStrings.referral_code,
          toolId: matchingQuotingToolVersion.versionId,
          quotingToolVersionId: matchingQuotingToolVersion.id,
          lastCompletedStep: (progress + 1).toString(),
          currentProgressUrl: `Quote-Step-0${progress + 1}`,
          // agreedToTerms,
        })
        setLoading(false)
        setShowHoldingPage(false);
        setShowBenefits(false)

        if (result.isSuccess) {
          if (!quoteId && result.value.id) {
            navigate(`/tenants/app${toolId ? `/${toolId}` : ""}/signup/${result.value.id}#Quote-Step-02`)
          } else {
            await getExistingQuote()
            window.location.hash = `Quote-Step-0${progress + 2}`
            setProgress(progress + 1)
          }
          setErrors([])
          return
        }
        else {
          setErrors(result.errors.map(error => error.reason))
          return
        }
      } else {
        // Need to finalise quote
        setLoading(true)
        setShowHoldingPage(true)
        var result = await finaliseQuote(
          quoteId,
          tenancyDetails,
          packageDetails,
          (progress + 1).toString(),
          `Quote-Step-0${progress + 1}`
        )
        setLoading(false)
        setShowHoldingPage(false)
        if (result.isSuccess) {
          // The result of finalising the quote is a url where the customer can complete their sign up
          window.location.href = result.value
        } else {
          setErrors(result.errors)
          window.scrollTo({ top: 0 })
        }
      }
    }

    const goToPage = (newStep) => {
      window.location.hash = `Quote-Step-0${newStep + 1}`
      setProgress(newStep)
    }

    const goToPreviousPage = () => {
      window.location.hash = `Quote-Step-0${progress}`
      setProgress(progress - 1)
    }


    if (progress < matchingQuotingToolPages.length) {
      let currentQuotingToolPage = matchingQuotingToolPages[progress]

      return (
        <Wrapper
          location={location}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
          group={{
            quote: {
              ...quoteExpiry,
            },
          }}
          toolId={toolId}
          quoteId={quoteId}
          quoteSummaryData={{
            services: services,
            tenancyDetails: JSON.stringify(tenancyDetails),
            packageDetails: JSON.stringify(packageDetails),
          }}
          summaryContent={
            progress < 1 && (
              <div style={{ textAlign: "center" }}>
                <div className={classes.quoteSummaryTagline}>
                  Household bills the <span style={{ color: '#08033D', fontFamily: "WorkSansExtraBold" }}>smart way.</span>
                </div>
                <div className={classes.quoteSummaryDescription}>Gas. Electricity. Water. Broadband.</div>
                <div style={{ marginBottom: 40 }} className={classes.quoteSummaryDescription}>One monthly bill. One point of contact.</div>
                <img className={classes.phoneMockup} src={PhoneMockup} />
              </div>)
          }
          showTrustpilotWidget={matchingQuotingToolVersion.showTrustpilotWidget}
          isHomeownerOrRenter={isHomeownerOrRenter}
          showHoldingPage={showHoldingPage}
          progress={progress}
        >
          <MainWrapper>
            <QuoteFormContainer
              errors={errors}
              loading={loading}
              progress={progress}
              services={services}
              setErrors={setErrors}
              tenancyDetails={tenancyDetails}
              packageDetails={packageDetails}
              isScottishPostcode={isScottishPostcode}
              quotingToolPage={currentQuotingToolPage}
              goToPage={(newStep) => goToPage(newStep)}
              isHomeownerOrRenter={isHomeownerOrRenter}
              goBack={progress > 0 && goToPreviousPage}
              availableBroadbandProducts={availableBroadbandProducts}
              matchingQuotingToolVersion={matchingQuotingToolVersion}
              onContinue={() => handleContinueFromTenancyDetailsPage()}
              setTenancyDetails={payload => setTenancyDetails(payload)}
              setPackageDetails={payload => setPackageDetails(payload)}
              energyAllowanceControl={matchingQuotingToolVersion.energyAllowanceControl}
              energyAllowanceProductIds={matchingQuotingToolVersion.energyAllowanceProductIds}
              hideContinue={
                matchingQuotingToolVersion.nonTrackingQuotes &&
                progress === matchingQuotingToolPages.length - 1
              }
              restrictionMode={restrictionMode}
            />
          </MainWrapper>
        </Wrapper>
      )
    }
  }

  return (
    <>
      {matchingQuotingToolVersion.customCSSURL && (
        <link
          rel="stylesheet"
          type="text/css"
          href={matchingQuotingToolVersion.customCSSURL}
        />
      )}
      {renderContent()}
    </>
  )
}
