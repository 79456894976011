import React, { useRef, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, MainContent, PageTitle, Errors } from "../../components"

import { getFormFieldError, validateEnergySelected } from "../../services/errorValidation"
import QuoteForm from "./QuoteForm"
import Stepper from "../../../../components/stepper/Stepper"

import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  quoteFormContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  fixedSignupButtonWrapper: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    zIndex: 10000,
    backgroundColor: "#82D0FF",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  fixedSignupButton: {
    maxWidth: 660,
    width: "100%",
  },
  quoteContent: {
    padding: 0,
  },
}))

export default function QuoteFormContainer({
  errors,
  goBack,
  loading,
  services,
  apiError,
  progress,
  goToPage,
  onContinue,
  hideContinue,
  tenancyDetails,
  packageDetails,
  backgroundColor,
  quotingToolPage,
  setTenancyDetails,
  setPackageDetails,
  isScottishPostcode,
  isHomeownerOrRenter,
  energyAllowanceControl,
  energyAllowanceProductIds,
  availableBroadbandProducts,
  matchingQuotingToolVersion,
  restrictionMode
}) {
  const classes = useStyles()

  const signUpSectionRef = useRef()

  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [localError, setLocalError] = useState("")
  const [showFixedSignUp, setShowFixedSignUp] = useState(true)

  const getLocalError = () => {
    let firstFormFieldError = false

    let activeFields = quotingToolPage.quotingToolPageFields.filter(
      a =>
        !a.dependsOnField ||
        (tenancyDetails[a.dependsOnField] !== undefined &&
          tenancyDetails[a.dependsOnField].toString() == a.dependsOnFieldValue)
    )

    for (let i = 0; i < activeFields.length; i++) {
      let formField = activeFields[i]
      let formFieldError = getFormFieldError(
        formField,
        tenancyDetails,
        isHomeownerOrRenter
      )

      if (formFieldError) {
        firstFormFieldError = formFieldError
        break
      }
    }

    return firstFormFieldError
  }

  const updateLocalError = () => {
    if (submitAttempted) {
      const error = getLocalError()
      if (error) {
        setLocalError("Please ensure mandatory fields are complete and in the correct format, before clicking continue")
      } else {
        setLocalError(null)
      }
    }
  }

  const packageSelectionShowing =
    quotingToolPage.quotingToolPageFields &&
    quotingToolPage.quotingToolPageFields.filter(
      _ => _.type === "Package Selection"
    ).length > 0

  const onSubmit = () => {
    if (packageSelectionShowing && !validateEnergySelected(packageDetails) && matchingQuotingToolVersion.energyMandatory) {
      setLocalError("Please select an energy package");
      return false;
    }

    if (packageSelectionShowing && !packageDetails.length) {
      setLocalError("Please select a service");
      return false;
    }

    setSubmitAttempted(true)

    if (!getLocalError()) {
      onContinue()
    }
  }

  useEffect(() => {
    updateLocalError()
  }, [submitAttempted, tenancyDetails])


  let matchingQuotingToolPages =
    matchingQuotingToolVersion &&
    matchingQuotingToolVersion.quotingToolPages.sort((a, b) =>
      a.pageNumber > b.pageNumber ? 1 : -1
    )

  return (
    <MainContent className={classes.quoteContent}>
      <div
        className={classes.quoteFormContainer}
      >
        {!hideContinue && showFixedSignUp && packageSelectionShowing && (
          <div
            className={classes.fixedSignupButtonWrapper}
            style={{ background: backgroundColor }}
          >
            <div className={classes.fixedSignupButton}>
              <Button
                fullWidth={true}
                onClick={() => onSubmit()}
                showShine
                loading={loading}
                className="quotingTool-submitButton"
              >
                {quotingToolPage.submitButtonText || "Submit"}
              </Button>
            </div>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <MainContent.Section>
            <Stepper
              goBack={errors.length > 0 ? () => window.location.reload() : goBack}
              pages={matchingQuotingToolPages.map(page => ({ ...page, title: page.stepTitle }))}
              currentIndex={progress}
              goToPage={(newStep) => goToPage(newStep)}
              hideStepper={false}
            />
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <PageTitle style={{ textAlign: "left" }}>
                <div
                  className="quotingTool-pageTitle"
                  dangerouslySetInnerHTML={{
                    __html: errors.length > 0 ? "Oh oh..." : quotingToolPage.title,
                  }}
                ></div>
              </PageTitle>
              {errors.length > 0 &&
                <div style={{ marginTop: 20 }}> {parse(errors.join("<br>"))}</div>}
              {errors.length == 0 && quotingToolPage.description && (
                <div
                  style={{
                    fontWeight: "400",
                    color: "#000",
                    opacity: "0.54",
                    marginBottom: 20,
                  }}
                  className="quotingTool-pageDescription"
                >
                  {quotingToolPage.description}
                </div>
              )}
            </div>
              {errors.length == 0 &&
                <QuoteForm
                  formFields={quotingToolPage.quotingToolPageFields}
                  values={tenancyDetails}
                  onChange={payload => {
                    setTenancyDetails({ ...tenancyDetails, ...payload })
                  }}
                  submitAttempted={submitAttempted}
                  services={services}
                  availableBroadbandProducts={availableBroadbandProducts}
                  matchingQuotingToolVersion={matchingQuotingToolVersion}
                  packageDetails={packageDetails}
                  setPackageDetails={setPackageDetails}
                  energyAllowanceControl={energyAllowanceControl}
                  energyAllowanceProductIds={energyAllowanceProductIds}
                  backgroundColor={backgroundColor}
                  hideContinue={hideContinue}
                  isHomeownerOrRenter={isHomeownerOrRenter}
                  isScottishPostcode={isScottishPostcode}
                  restrictionMode={restrictionMode && progress === 0}
                />}
          </MainContent.Section>

          {!hideContinue && errors.length == 0 && (
            <div style={{ padding: 20, paddingTop: 0 }} ref={signUpSectionRef}>
              <Button
                style={{
                  width: "100%",
                  fontFamily: "tenon, sans-serif",
                  marginTop: 20,
                }}
                onClick={() => onSubmit()}
                loading={loading}
                showShine
                className="quotingTool-submitButton"
              >
                {quotingToolPage.submitButtonText || "Submit"}
              </Button>
              <Errors>{localError || apiError}</Errors>
            </div>
          )}
        </div>
      </div>
    </MainContent>
  )
}
