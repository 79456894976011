import React from "react"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import "./Stepper.css"

const Stepper = ({ goBack, pages, currentIndex, goToPage, hideStepper }) => {
    return (
        <div className="stepper">
            <div
                className="stepper-arrow-back"
                onClick={() => {
                    window.history.back()
                    if (goBack) {
                        goBack()
                    }
                }}
                style={{ cursor: "pointer" }}
            >
                <div>
                    <ArrowBackIcon />
                    <div>Back</div>
                </div>
            </div>
            {
                !hideStepper &&
                <div className="stepper-horizontal" id="stepper1">
                    {pages.map((page, index) => {
                        const active = currentIndex >= index;
                        return (
                            <div
                                className="step editing"
                                style={{
                                    width: `${100 / pages.length}%`,
                                    cursor: active ? "pointer" : "default",
                                    opacity: active ? 1 : 0.3,
                                }}
                                onClick={() => active && goToPage(index)}
                            >
                                {page.activeIcon && page.inactiveIcon && <img src={active ? page.activeIcon : page.inactiveIcon} />}
                                {!page.activeIcon && !page.inactiveIcon && <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }}><div style={{ background: '#82D0FF', width: '30px', height: '30px', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 3 }}>{index + 1}</div></div>}
                                <div
                                    style={{
                                    }}
                                    className="step-title"
                                >
                                    {page.title}
                                </div>
                                <div className="step-bar-left"></div>
                                <div className="step-bar-right"></div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default Stepper
