import React, { useState } from "react"
import {
  Dropdown,
  AddressLookup,
  ButtonRange,
  InputSection,
  PackageSelection,
  FormLine,
  Slider,
  Errors,
  FormLabels,
  SelectionButtons,
} from "../../components"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Checkbox from "@material-ui/core/Checkbox"

import { checkBroadbandAvailability as apiCheckBroadbandAvailability } from "../../../../services/tenantsApi"

import { getFormFieldError } from "../../services/errorValidation"

const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "tenon, sans-serif",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
  paragraph: {
    padding: "0px 20px",
    "& a": {
      textDecorationLine: "underline"
    }
  }
})

export default function QuoteForm({
  formFields,
  values,
  onChange,
  services,
  availableBroadbandProducts,
  matchingQuotingToolVersion,
  packageDetails,
  setPackageDetails,
  energyAllowanceControl,
  energyAllowanceProductIds,
  submitAttempted,
  backgroundColor,
  hideContinue,
  isHomeownerOrRenter,
  isScottishPostcode,
  restrictionMode
}) {
  const classes = useStyles()

  const [currentFieldIndex, setCurrentFieldIndex] = useState(0)
  const [focusedInput, setFocusedInput] = useState(-1)

  const cacheCheckBroadbandAvailability = async newAddress => {
    if (newAddress && newAddress.addressLine1) {
      try {
        apiCheckBroadbandAvailability(newAddress)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return formFields
    .filter(
      a =>
        !a.dependsOnField ||
        (values[a.dependsOnField] !== undefined &&
          values[a.dependsOnField].toString() == a.dependsOnFieldValue)
    )
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((formField, index) => {
      const value =
        values[formField.name] === undefined ? "" : values[formField.name]

      let error = getFormFieldError(formField, values, isHomeownerOrRenter)

      if (formField.type === "Paragraph") {
        return <div className={clsx(classes.paragraph)} dangerouslySetInnerHTML={{ __html: formField.title }} />
      } else if (
        formField.type === "Text" ||
        formField.type === "Email" ||
        formField.type === "Number"
      ) {
        return (
          <FormLine focusedInput={focusedInput === index}>
            <InputSection
              error={error}
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              placeholder={formField.placeholder || formField.title}
              popupText={formField.infoText}
              value={value}
              onChange={e => {
                onChange({ [formField.name]: e.target.value })
              }}
              helperText={error}
              name={formField.name}
              type={formField.type.toLowerCase()}
              submitAttempted={submitAttempted}
              onFocus={() => setFocusedInput(index)}
              onBlur={() => setFocusedInput(-1)}
              autocomplete={formField.autocomplete}
              min={formField.minimumValue}
              max={formField.maximumValue}
              required={formField.required}
              maxLength="100"
              restrictionMode={restrictionMode}
            />
          </FormLine>
        )
      } else if (formField.type === "Date") {
        return (
          <FormLine focusedInput={focusedInput === index}>
            <InputSection
              error={error}
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              placeholder="YYYY-MM-DD"
              popupText={formField.infoText}
              value={value}
              onChange={e => {
                onChange({ [formField.name]: e.target.value })
              }}
              helperText={error}
              name={formField.name}
              type={formField.type.toLowerCase()}
              submitAttempted={submitAttempted}
              onFocus={() => setFocusedInput(index)}
              onBlur={() => setFocusedInput(-1)}
              autocomplete={formField.autocomplete}
              min={formField.minimumValue}
              max={formField.maximumValue}
              required={formField.required}
              maxLength="100"
              isMoveInDate={(formField.name === "TenancyStart" || formField.name === "MoveInDate") ? true : false}
              inputProps={{
                min: "1900-01-01"
              }}
            />
          </FormLine>
        )
      } else if (formField.type === "Phone") {
        return (
          <FormLine focusedInput={focusedInput === index}>
            <InputSection
              error={error}
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              placeholder={formField.placeholder || formField.title}
              popupText={formField.infoText}
              value={value}
              onChange={e => {
                onChange({ [formField.name]: e.target.value })
              }}
              helperText={error}
              name={formField.name}
              submitAttempted={submitAttempted}
              onFocus={() => setFocusedInput(index)}
              onBlur={() => setFocusedInput(-1)}
              autocomplete={formField.autocomplete}
              required={formField.required}
              disabled={restrictionMode}
            // type="phone"
            />
          </FormLine>
        )
      } else if (formField.type === "Address") {
        return (
          <FormLine focusedInput={focusedInput === index}>
            <AddressLookup
              error={error}
              helperText={error}
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              value={value}
              onChange={payload => {
                onChange({ [formField.name]: payload })
                cacheCheckBroadbandAvailability(payload)
              }}
              required={formField.required}
              submitAttempted={submitAttempted}
              onFocus={() => setFocusedInput(index)}
              onBlur={() => setFocusedInput(-1)}
            />
          </FormLine>
        )
      } else if (formField.type === "Checkbox") {
        return (
          <FormLine>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Checkbox
                color="primary"
                id={formField.id}
                name={formField.id}
                checked={value}
                onChange={e => {
                  onChange({
                    [formField.name]: e.target.checked,
                  })
                }}
                className={clsx(classes.checkbox, "quotingTool-checkbox")}
              />
              <div
                htmlFor={formField.id}
                dangerouslySetInnerHTML={{
                  __html: formField.title,
                }}
                className={clsx(
                  classes.checkboxLabel,
                  "quotingTool-checkboxLabel"
                )}
              />
            </div>
            {submitAttempted && (
              <Errors style={{ marginTop: 0, marginBottom: 10 }}>
                {error}
              </Errors>
            )}
          </FormLine>
        )
      } else if (formField.type === "Yes No") {
        return (
          <FormLine>
            <FormLabels
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              popupText={formField.infoText}
            />
            <SelectionButtons
              selector={value}
              btn1Text={"Yes"}
              btn2Text={"No"}
              onClick1={() =>
                onChange({
                  [formField.name]: true,
                })
              }
              onClick2={() => {
                onChange({
                  [formField.name]: false,
                })
              }}
            />
            {submitAttempted && (
              <Errors style={{ marginTop: 0, marginBottom: 10 }}>
                {error}
              </Errors>
            )}

          </FormLine >
        )
      } else if (
        formField.type === "Dropdown" ||
        formField.type === "Multi Select Dropdown"
      ) {
        return (
          <>
            <FormLine>
              <Dropdown
                error={submitAttempted && error}
                helperText={submitAttempted && error}
                fieldIndex={index}
                placeholder={formField.placeholder || formField.title}
                label={formField.title}
                description={formField.description}
                labels={formField.dropdownOptions.split("\n").filter(label => {
                  if (formField.maximumValueFormula) {
                    let maximumValue = formField.maximumValueFormula
                    formFields.forEach(formField => {
                      maximumValue = maximumValue.replace(
                        `#${formField.name.replace(/ /g, "")}`,
                        values[formField.name]
                      )
                    })

                    if (maximumValue.length < 7) {
                      let maximumValueInt = eval(maximumValue)
                      let labelInt = parseInt(label)

                      if (labelInt <= maximumValueInt) {
                        return true
                      }
                    }
                    return false
                  }
                  return true
                })}
                value={value}
                setValue={payload => {
                  onChange({ [formField.name]: payload })
                }}
                multiple={formField.type === "Multi Select Dropdown"}
                popupText={formField.infoText}
                studioOption={formField.name === "NumberOfBedrooms"}
              />
            </FormLine>
          </>
        )
      } else if (formField.type === "Button Range") {
        return (
          <FormLine>
            <ButtonRange
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              min={formField.minimumValue}
              max={formField.maximumValue}
              rangeUnits={formField.rangeUnits}
              value={value}
              onChange={val => {
                onChange({
                  [formField.name]: val,
                })
              }}
              popupText={formField.infoText}
            />
          </FormLine>
        )
      } else if (formField.type === "Slider") {
        return (
          <FormLine>
            <Slider
              fieldIndex={index}
              label={formField.title}
              description={formField.description}
              min={formField.minimumValue}
              max={formField.maximumValue}
              marks={[
                ...Array(formField.maximumValue + 1 - formField.minimumValue),
              ].map((val, index) => ({
                label: (index + formField.minimumValue).toString(),
                value: index + formField.minimumValue,
              }))}
              value={value}
              onChange={val => {
                onChange({
                  [formField.name]: val,
                })
              }}
              popupText={formField.infoText}
            />
          </FormLine>
        )
      } else if (formField.type === "Package Selection") {
        return (
          <PackageSelection
            services={services}
            availableBroadbandProducts={availableBroadbandProducts}
            products={formField.products}
            showServicePrices={formField.showServicePrices}
            showWeeklyPrice={formField.showWeeklyPrice}
            showMonthlyPrice={formField.showMonthlyPrice}
            showYearlyPrice={formField.showYearlyPrice}
            value={value}
            tenancyDetails={values}
            setTenancyDetails={payload => onChange(payload)}
            packageDetails={packageDetails}
            setPackageDetails={payload => {
              setPackageDetails(payload)
            }}
            matchingQuotingToolVersion={matchingQuotingToolVersion}
            energyAllowanceControl={energyAllowanceControl}
            energyAllowanceProductIds={energyAllowanceProductIds}
            label={formField.title}
            description={formField.description}
            popupText={formField.infoText}
            backgroundColor={backgroundColor}
            hideContinue={hideContinue}
            isHomeownerOrRenter={isHomeownerOrRenter}
            isScottishPostcode={isScottishPostcode}
          />
        )
      }
    })
}
