import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import MuiAutocomplete from "@material-ui/lab/Autocomplete"
import MuiTextField from "@material-ui/core/TextField"
import MuiCircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles(() => ({
  autocomplete: {
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
  },
  muiAutocomplete: {
    width: "100%",
  },
  errorText: {
    color: "#F44336",
    fontSize: "12px",
    fontFamily: "mundial",
  },
  textInput: {
    "& .MuiInputBase-root": {
      backgroundColor: "#FAFAFA",
    },
  },
}))

const ApiAutocomplete = ({
  onChange,
  value,
  inlineLabel,
  useGetOptions,
  formatData,
  allowInitialEmptySearch,
  initialTextValue,
  typeToSearchMessage,
  ...props
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState(initialTextValue || "")

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const { data, refetch, isLoading } = useGetOptions(debouncedSearchTerm, {
    enabled: allowInitialEmptySearch,
  })

  const selectedValue =
    value &&
    data &&
    formatData(data.value).find(dataItem => dataItem.value === value)

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      refetch()
    }
  }, [debouncedSearchTerm, refetch])

  return (
    <FormControl
      variant="outlined"
      className={classes.autocomplete}
      style={{ marginBottom: 10, width: "100%" }}
    >
      <MuiAutocomplete
        filterOptions={x => x}
        className={classes.muiAutocomplete}
        id="combo-box-demo"
        options={
          data && data.value && Array.isArray(data.value)
            ? formatData(data.value)
            : []
        }
        getOptionLabel={option => {
          return option.label || ""
        }}
        value={selectedValue || null}
        onChange={(e, newVal) => {
          onChange({ target: { value: newVal ? newVal.value : "" } })

          if (newVal && newVal.label) {
            setInputValue(newVal.label)
          } else {
            setInputValue("")
          }
        }}
        inputValue={inputValue || (selectedValue && selectedValue.label) || ""}
        onInputChange={(event, value, reason) => {
          if (reason === "input") {
            setInputValue(value)
            onChange({ target: { value: "" } })
          }
        }}
        onBlur={() => !selectedValue && setInputValue("")}
        loading={isLoading}
        includeInputInList
        renderInput={params => (
          <MuiTextField
            {...params}
            className={classes.textInput}
            variant="outlined"
            placeholder={props.placeholder}
            error={props.error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <MuiCircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        getOptionSelected={(option, value) => option.value === value.value}
        noOptionsText={
          !inputValue || (inputValue.length < 3 && typeToSearchMessage)
            ? typeToSearchMessage
            : "No options"
        }
        {...props}
      />
      {props.error ? (
        <div className={classes.errorText}>{props.helperText}</div>
      ) : null}
    </FormControl>
  )
}

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export default ApiAutocomplete
